import React, { useState } from "react";
import ResellerData from "./ResellerData";
import NonResellerData from "./NonResellerData";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
const Wrappar = ({
  workflows,
  BootstrapInput,
  handleimage,
  striperecurringprod,
  stripeProducts,
  Catgories,
  Purchasedapps,
  snapshots,
}) => {
  const [Resale, setResale] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setisLastStep] = useState(true);
  const handleResale = (value) => {
    setResale(value);
    if (value == true) {
      if(activeStep==1){

        setisLastStep(true);
      }else{
        setisLastStep(false);
      }
    } else {
      setisLastStep(true);
    }
  };
  const handleNext = () => {
    setActiveStep(1);
    if (Resale == true && activeStep==0) {
        setisLastStep(true);
    } else {
      setisLastStep(false);
    }
  };
  const handleBack = () => {
    setActiveStep(0);
    setisLastStep(false)
    
  };
  return (
    <>
    {
       activeStep == 1 ?
       <></>
       :
      <Grid item md={12}>
        <MDBox mb={1}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              <MDTypography
                variant="h6"
                sx={{ fontSize: "17px", fontWeight: "400" }}
                display="block"
                gutterBottom
              >
                Is it resaleable ?
              </MDTypography>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name={"resale"}
              sx={{ marginTop: "-6px" }}
            >
              {Resale == false ? (
                <>
                  <FormControlLabel
                    value="yes"
                    onClick={() => handleResale(true)}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => handleResale(false)}
                    control={<Radio />}
                    checked
                    label="No"
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    value="yes"
                    checked
                    onClick={() => handleResale(true)}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    onClick={() => handleResale(false)}
                    control={<Radio />}
                    label="No"
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
        </MDBox>
      </Grid>
    }
      <MDBox sx={{ display: activeStep == 1 ? "" : "none" }}>
        <ResellerData
          BootstrapInput={BootstrapInput}
          snapshots={snapshots}
          striperecurringprod={striperecurringprod}
          stripeProducts={stripeProducts}
          Purchasedapps={Purchasedapps}
          workflows={workflows}
          Catgories={Catgories}
          handleimage={handleimage}
        />
      </MDBox>
      <MDBox sx={{ display: activeStep == 0 ? "" : "none" }}>
        <NonResellerData
          BootstrapInput={BootstrapInput}
          snapshots={snapshots}
          striperecurringprod={striperecurringprod}
          stripeProducts={stripeProducts}
          Purchasedapps={Purchasedapps}
          workflows={workflows}
          Catgories={Catgories}
          handleimage={handleimage}
        />
      </MDBox>
      {isLastStep ? (
        <MDBox display="flex" justifyContent={activeStep==1 ? "space-between" :"flex-end"} mt={2}>
        {activeStep==1 ?
        
          <MDButton variant="gradient" color="dark" onClick={handleBack}>
            back
          </MDButton>
          :
          <></>
      }
        
        <MDButton variant="gradient" color="dark" type="submit">
          Save
        </MDButton>
        
        </MDBox>
      ) : (
        <MDBox display="flex" justifyContent="flex-end" mt={2}>
          <MDButton variant="gradient" color="dark" onClick={handleNext}>
            next
          </MDButton>
        </MDBox>
      )}
      <input type="hidden" name="resellable" value={Resale}/>
    </>
  );
};

export default Wrappar;
