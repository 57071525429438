/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React layouts
import Dashboard from "./dashboard";
// import Tables from './tables'
// import Billing from './billing'
// import Notifications from './notifications'
// import Profile from './profile'
import ReceiptIcon from "@mui/icons-material/Receipt";
import Store from "./Store";
import Tasks from "./Tasks/Tasks";
import BasicInfo from "./SellerProfile";
import Purchases from "./Purchases/Purchases";
import Agencies from "./Agencies/Agencies";

function useRoutes() {
  return [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: <Dashboard />,
    },
    {
      type: "collapse",
      name: "Store",
      key: "store",
      icon: <Icon fontSize="small">store</Icon>,
      route: "/store",
      component: <Store />,
    },
    {
      type: "collapse",
      name: "Purchases",
      key: "Purchase",

      icon: <ReceiptIcon fontSize="small" />,
      // route: "/purchases",
      // component: <Purchases />,

      collapse: [
        {
          type: "collapse",
          name: "All",
          key: "all",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases/all",
          component: <Purchases />,
        },
        {
          type: "collapse",
          name: "Bundles",
          key: "purchases_bundle",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases/bundle",
          component: <Purchases />,
        },
        {
          type: "collapse",
          name: "Services",
          key: "purchases_service",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases/service",
          component: <Purchases />,
        },
        {
          type: "collapse",
          name: "Resellings",
          key: "purchases_resalling",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases/resalling",
          component: <Purchases />,
        },
        {
          type: "collapse",
          name: "Direct",
          key: "purchases_direct",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases/direct",
          component: <Purchases />,
        },
        {
          type: "collapse",
          name: "Subscriptions",
          key: "purchases_subscriptions",
          icon: <ReceiptIcon fontSize="small" />,
          route: "/purchases/subscriptions",
          component: <Dashboard />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Tasks",
      key: "task",
      icon: <Icon fontSize="small">task</Icon>,
      route: "/task",
      component: <Tasks />,
    },
    {
      type: "collapse",
      name: "Agencies",
      key: "Agencies",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/agencies",
      component: <Agencies />,
    },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: <BasicInfo />,
    },
  ];
}

export default useRoutes;
