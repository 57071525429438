/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import env from "config";
// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import rank1 from "assets/ranks/rank1.jpeg";
import rank2 from "assets/ranks/rank2.jpeg";
import rank3 from "assets/ranks/rank3.jpeg";
import rank4 from "assets/ranks/rank4.jpeg";
import rank5 from "assets/ranks/rank5.jpeg";
import localforage from "localforage";
import React from "react";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
const Data = (props) => {
  const AppService = useAppServices();
  const location_settings = useAgencyInfo()[4];
  const [Bundles, setBundles] = React.useState([]);
  const onLoad = () => {
    console.log(props, "snapopropspropsprops");
    const data = props.data.sort((a, b) =>
      parseFloat(a.amo?.amount_spend) < parseFloat(b.amo?.amount_spend)
        ? 1
        : parseFloat(b.amo?.amount_spend) < parseFloat(a.amo?.amount_spend)
        ? -1
        : 0
    );
    setBundles(data);
  };
  React.useEffect(async () => {
    onLoad();
  }, []);
  const avatars = (members) =>
    members.map(([image, name]) => (
      <Tooltip key={name} title={name} placeholder="bottom">
        <MDAvatar
          src={image}
          alt="name"
          size="xs"
          sx={{
            border: ({ borders: { borderWidth }, palette: { white } }) =>
              `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",

            "&:not(:first-of-type)": {
              ml: -1.25,
            },

            "&:hover, &:focus": {
              zIndex: "10",
            },
          }}
        />
      </Tooltip>
    ));

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "no", accessor: "no", width: "10%", align: "left" },
      { Header: "name", accessor: "name", width: "25%", align: "left" },
      {
        Header: "amount spent",
        accessor: "amount_spent",
        width: "10%",
        align: "left",
      },
    ],

    rows: [
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank1}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[0]?._id?.name}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[0]
              ? location_settings.currency_sign + Bundles[0]?.amount_spend
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank2}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[1]?._id?.name}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[1]
              ? location_settings.currency_sign + Bundles[1]?.amount_spend
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank3}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[2]?._id?.name}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[2]
              ? location_settings.currency_sign + Bundles[2]?.amount_spend
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank4}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[3]?._id?.name}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[3]
              ? location_settings.currency_sign + Bundles[3]?.amount_spend
              : ""}
          </MDTypography>
        ),
      },
      {
        no: (
          <MDBox display="flex" py={1}>
            <MDAvatar
              src={rank5}
              alt="name"
              size="xs"
              sx={{
                border: ({ borders: { borderWidth }, palette: { white } }) =>
                  `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              }}
            />
          </MDBox>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[4]?._id?.name}
          </MDTypography>
        ),
        amount_spent: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {Bundles[4]
              ? location_settings.currency_sign + Bundles[4]?.amount_spend
              : ""}
          </MDTypography>
        ),
      },
    ],
  };
};
export default Data;
