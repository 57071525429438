import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Integration from "./components/Integration";
import Header from "./components/Header";
import Card_details from "./components/Card_details/Card_details";
import Profile from "./components/Profile";
// import Footer from 'examples/Footer'
// import ProfilesList from 'examples/Li
// import Profile from "./components/Profile"
// import Card_details from "./components/Card_details/Card_details"
function Settings() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container mt={10}>
        <Grid item xs={12}>
          <Header elements={[<Integration />, <Card_details />, <Profile />]} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Settings;
