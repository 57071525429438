import makeAnimated from "react-select/animated";
import Select from "react-select";
import React, { useRef, useState, useEffect } from "react";

const animatedComponents = makeAnimated();
const Multiselect = (props) => {
  const [data, setdata] = useState([]);
  const [values, setvalues] = useState([]);

  const handlechange = (e) => {
    console.log(JSON.stringify(e));
    setvalues(JSON.stringify(e));
  };
  useEffect(async () => {
    if (props.name == "category") {
      console.log(props, "categorycategorycategory");
    }
    console.log(props, "props");
  }, []);

  return (
    <>
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={[]}
        onChange={handlechange}
        isMulti
        options={props.data}
      />
      <input type="hidden" value={values} name={props.name} />
    </>
  );
};
export default Multiselect;
