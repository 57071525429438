import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import env from "config";
import { useAppServices } from "hook/services";
const Default = (props) => {
  const Service = useAppServices();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency_id, setagency_id] = useState();
  const [processing, setProcessing] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(async () => {
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
    console.log(props, "props");
  }, []);
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setProcessing(true);
      const payload = {
        _id: props.data._id,
        status: "completed",
        invite_link: "e.target.invite_link.value",
      };
      console.log(payload);
      const { response } = await Service.Tasks.sellerupdate({ payload });
      if (response) {
        setProcessing(false);
        props.handlerefresh();
        onClose();
      }
    };
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Make Complete
          </MDTypography>
          <Grid item md={12}>
            <MDBox mb={1}>
              <input
                type="text"
                placeholder="Invite Link"
                name="invite_link"
                className="form-control"
              />
            </MDBox>
          </Grid>
          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                loading={processing}
                disabled={processing}
                fullWidth
              >
                Confirm
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        size="small"
        variant="gradient"
        color="primary"
        onClick={() => setOpenAddProduct(true)}
      >
        Make Complete
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Default;
