import { FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import SubscriptionPayable from "./components/subscriptionpayable";
import Payable from "./components/Payable";
import Multiselect from "../Multi_Selecte/Multiselect";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useAgencyInfo } from "context/agency";
import TagsInput from "components/TagsInput";

const Wrappar = ({
  workflows,
  BootstrapInput,
  handleimage,
  striperecurringprod,
  stripeProducts,
  Catgories,
  Purchasedapps,
  snapshots,
}) => {
  const [agency] = useAgencyInfo();
  const [refresh, setrefresh] = useState(false);
  const [lockfield, setlockfield] = useState({
    name: false,
    description: false,
    image_file: false,
    snapshot: false,
    snapshot_string: false,
    apps: false,
    iframe_url: false,
    doc: false,
    management_sub: false,
    setup_fee: false,
  });
  const handlelock = async (name, action) => {
    // alert(name)
    var temp = lockfield;
    temp[name] = action;
    // console.log(temp)
    setlockfield(temp);
    setrefresh(!refresh);
  };
  return (
    <>
      <Grid item md={12}>
        <MDBox mb={1}>
          <MDTypography variant="button" display="block" gutterBottom>
            What is your bundle called?
          </MDTypography>
          <MDBox mb={1} display={"flex"}>
            <BootstrapInput
              defaultValue=""
              id="bootstrap-input"
              fullWidth
              placeholder="name"
              name="loc_name"
            />
            {lockfield["name"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("name", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("name", false)}
              />
            )}
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item md={12}>
        <MDBox mb={1} >
          <MDTypography variant="button" display="block" gutterBottom>
            Add your bundle description.
          </MDTypography>
          <MDBox display={"flex"}>

          <TextareaAutosize
            aria-label="minimum height"
            minRows={1}
            className="form-control"
            name="loc_description"
            placeholder="description"
            style={{ width: "100%", paddingLeft: "13px" }}
          />
          {lockfield["description"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("description", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("description", false)}
              />
            )}
          </MDBox>
        </MDBox>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <MDTypography variant="button" display="block" gutterBottom>
            Upload a cover image for your bundle
          </MDTypography>
          <MDBox py={2}>
            <input
              type="file"
              name="loc_image"
              className="form-control h-1"
              onChange={(e)=>handleimage(e,'selling')}

            />

            <p className="img_text">
              Upload a 640 x 360 pixel PNG, GIF, or JPG.
            </p>
          </MDBox>
        </Grid>
        <Grid item md={4} mt={1} className="img_adj" display={'flex'} justifyContent={'flex-end'}>
          <img
            src="https://via.placeholder.com/100"
            width={80}
            height={80}
            alt=""
          />
            {lockfield["image_file"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("image_file", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("image_file", false)}
              />
            )}
        </Grid>
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          What is the URL that tells customers about your bundle?
        </MDTypography>
        <MDBox mb={1} display="flex">
          <BootstrapInput
            defaultValue=""
            id="bootstrap-input"
            fullWidth
            placeholder="https://www.example.com/"
            name="loc_iframe_url"
          />
           {lockfield["iframe_url"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("iframe_url", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("iframe_url", false)}
              />
            )}
        </MDBox>
      </Grid>

      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          What is the URL with documentation about this bundle?
        </MDTypography>
        <MDBox mb={1} display="flex">
          <BootstrapInput
            defaultValue=""
            id="bootstrap-input"
            fullWidth
            placeholder="https://www.example.com/"
            name="loc_doc"
          />
            {lockfield["doc"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("doc", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("doc", false)}
              />
            )}
        </MDBox>
      </Grid>
      
      <Payable
        data={stripeProducts}
        edit_data={""}
        payablename="cost_setup_payable"
        label="is there's a Cost setup fee"
        name={"cost_setup_fee"}
      />
      <SubscriptionPayable
        data={striperecurringprod}
        edit_data={""}
        payablename="cost_management_payable"
        label="is there's a Cost management subscriptions"
        name={"cost_management_sub"}
      />
      <MDBox mb={1} display="flex">
          
      <Payable
        data={stripeProducts}
        edit_data={""}
        payablename="selling_setup_payable"
        label="is there's a Selling setup fee"
        name={"selling_setup_fee"}
      />
            {lockfield["setup_fee"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("setup_fee", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("setup_fee", false)}
              />
            )}
        </MDBox>
        <MDBox mb={1} display="flex">
      <Payable
        data={stripeProducts}
        edit_data={""}
        payablename="selling_management_payable"
        label="is there's a Selling management subscriptions"
        name={"selling_management_sub"}
      />
            {lockfield["management_sub"] == false ? (
              <LockOpenIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("management_sub", true)}
              />
            ) : (
              <LockIcon
                mt={2}
                sx={{ margin: "10px" }}
                onClick={() => handlelock("management_sub", false)}
              />
            )}
        </MDBox>
      <input type="hidden" name="lockfield" value={JSON.stringify(lockfield)}/>
    </>
  );
};

export default Wrappar;
