/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "./data/reportsBarChartData";
import reportsLineChartData from "./data/reportsLineChartData";

// Dashboard components
import Projects from "./components/Projects";
import Categories from "./components/categories";
import Apps from "./components/locations";
import Agencies from "./components/agencies";
import Snapshots from "./components/snapshots";
import Services from "./components/services";
import OrdersOverview from "./components/OrdersOverview";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import localforage from "localforage";
import { useEffect, useState } from "react";
import env from "config";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
function Dashboard() {
  const [Users_data, setUsers_data] = useState([]);
  const [processing, setprocessing] = useState(true);
  const AppService = useAppServices();
  const location_settings = useAgencyInfo()[4];
  // if (!location_settings.currency_sign) {
  //   // alert("dkjh");
  //   updateLocationSettings();
  // }
  const [agency] = useAgencyInfo();
  const [newusers, setnewusers] = useState("");
  const { sales, tasks } = reportsLineChartData;
  // const BASE_URL = `${env.API_URL}/v1`;
  var purchases = {
    labels: [
      "jan,feb,march,Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: {
      label: "Purchases",
      data: [15, 10, 20, 50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
  };
  var data = {
    snapshots_sales: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: {
        label: "purchases",
        data: [
          Users_data?.snapshots_sales?.jan,
          Users_data?.snapshots_sales?.feb,
          Users_data?.snapshots_sales?.mar,
          Users_data?.snapshots_sales?.apr,
          Users_data?.snapshots_sales?.may,
          Users_data?.snapshots_sales?.jun,
          Users_data?.snapshots_sales?.jul,
          Users_data?.snapshots_sales?.agu,
          Users_data?.snapshots_sales?.sep,
          Users_data?.snapshots_sales?.oct,
          Users_data?.snapshots_sales?.nov,
          Users_data?.snapshots_sales?.dec,
        ],
      },
    },
    app_sales: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: {
        label: "purchases",
        data: [
          Users_data?.apppurchases?.jan,
          Users_data?.apppurchases?.feb,
          Users_data?.apppurchases?.mar,
          Users_data?.apppurchases?.apr,
          Users_data?.apppurchases?.may,
          Users_data?.apppurchases?.jun,
          Users_data?.apppurchases?.jul,
          Users_data?.apppurchases?.agu,
          Users_data?.apppurchases?.sep,
          Users_data?.apppurchases?.oct,
          Users_data?.apppurchases?.nov,
          Users_data?.apppurchases?.dec,
        ],
      },
    },
    storeviews: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: {
        label: "store views",
        data: [
          Users_data?.storeviews?.jan,
          Users_data?.storeviews?.feb,
          Users_data?.storeviews?.mar,
          Users_data?.storeviews?.apr,
          Users_data?.storeviews?.may,
          Users_data?.storeviews?.jun,
          Users_data?.storeviews?.jul,
          Users_data?.storeviews?.agu,
          Users_data?.storeviews?.sep,
          Users_data?.storeviews?.oct,
          Users_data?.storeviews?.nov,
          Users_data?.storeviews?.dec,
        ],
      },
    },
  };
  const getDashboardData = async (e) => {
    // alert(["jkdh"]);
    const { response } = await AppService.snapshots.getsellerdashboard({
      query: `id=${agency._id}`,
    });
    console.log(response, "getDashboardData");
    if (response) {
      setUsers_data(response.data);
      var temp_users = 0;
      setnewusers(response.data.users.length);
      response.data.users.forEach((element) => {
        if (element.roles.includes("admin")) {
        }
        temp_users++;
      });
      setprocessing(false);
    } else {
      setUsers_data([]);
      setprocessing(false);
    }
  };
  // var axios = require('axios');
  const onLoad = () => {
    getDashboardData();
  };
  useEffect(async () => {
    onLoad();
    //     const localBrand = await localforage.getItem('user');

    //   axios.get(BASE_URL+'/snapshot/create_snapshot/getdashboard/' + localBrand.agency_id).then((response) => {
    //     console.log(response.data.data.purchases, 'purchases');
    //     //  data={  sales: {
    //     //   labels   : ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Oct', 'Nov', 'Dec'],
    //     //   datasets : { label: 'Mobile apps', data: [50, 40, 300, 320, 500, 350, 200, 230, 500,200, 230, 500] },
    //     // },
    //     // tasks: {
    //     //   labels   : ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Oct', 'Nov', 'Dec'],
    //     //   datasets : { label: 'Desktop apps', data: [response.data.data.purchases.jan, response.data.data.purchases.feb, 300, 220, 500, 250, 400, 230, 500,200, 230, 500] },
    //     // },}
    //     setUsers_data(response.data.data)
    //     var temp_users=0;
    // //     function formatDate(date) {
    // //       var d = new Date(date),
    // //           month = '' + (d.getMonth() + 1),
    // //           day = '' + d.getDate(),
    // //           year = d.getFullYear();

    // //       if (month.length < 2)
    // //           month = '0' + month;
    // //       if (day.length < 2)
    // //           day = '0' + day;

    // //       return [year, month].join('-');
    // //   }
    // //   var today = new Date();
    // // var dd = String(today.getDate()).padStart(2, '0');
    // // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // // var yyyy = today.getFullYear();

    // // today = yyyy + '/' + mm + '/' + dd;
    //   //  alert(today)
    //   // alert(formatDate('2023-01-22T15:53:35.589+00:00'));
    //     response.data.data.users.forEach(element => {
    //       if (element.roles.includes('admin')) {
    //         temp_users++
    //       }
    //     });
    //     setnewusers(temp_users)
    //     // setpurchases(response.data.purchases)
    //     // setrevenue(response.data.revenue)
    // })
    //   .catch(function (error) {
    //     // console.log(error);

    //   });
  }, []);

  const name = ["Zohaib", "Hamza", "Awais"];
  const amount_spent = ["$567", "$456", "$156"];
  const number_of_locations = ["7", "3", "2"];
  return (
    <DashboardLayout>
      <DashboardNavbar type="agency" />
      {processing ? (
        <Loader />
      ) : (
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Purchases"
                  count={Users_data?.total_purchases}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Profile Views"
                  count={Users_data?.storeviews?.total}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Revenue"
                  count={
                    Users_data.totalrevenue != undefined
                      ? location_settings.currency_sign +
                        Users_data?.totalrevenue
                      : ""
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="New Agencies"
                  count={newusers}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="Profile Views"
                    description=""
                    date=""
                    chart={data.storeviews}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="Bundles/Services Sales"
                    description={""}
                    date=""
                    chart={data?.snapshots_sales}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="dark"
                    title="Widgets/Apps Sales"
                    description=""
                    date=""
                    chart={data?.app_sales}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Agencies
                  heading={"Top 5 Agencies"}
                  data={Users_data.top_agencies}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Apps heading={"Top 5 Apps"} />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12} md={4}>
                <Snapshots
                  heading={"Top 5 Bundles"}
                  data={Users_data.top_bundles}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Services
                  heading={"Top 5 Services"}
                  data={Users_data.top_services}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Categories heading={"Top 5 Categories"} />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
