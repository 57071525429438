/**
=========================================================
* MD UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/MD-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the MDware.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// MD UI Dashboard PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import NonResellerData from "../NonResellerData";
import ResellerData from "../ResellerData";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import MDTypography from "components/MDTypography";
// Wizard page components

function getSteps(Resale) {
  if (Resale) {
    return ["Non Saleable", "Saleable"];
  } else {
    return ["Non Saleable"];
  }
}

function getStepContent({
  activeStep,
  workflows,
  BootstrapInput,
  handleimage,
  striperecurringprod,
  stripeProducts,
  Catgories,
  Purchasedapps,
  snapshots,
}) {
  switch (activeStep) {
    case 0:
      return (
        <NonResellerData
          BootstrapInput={BootstrapInput}
          snapshots={snapshots}
          striperecurringprod={striperecurringprod}
          stripeProducts={stripeProducts}
          Purchasedapps={Purchasedapps}
          workflows={workflows}
          Catgories={Catgories}
          handleimage={handleimage}
        />
      );
    case 1:
      return (
        <ResellerData
          BootstrapInput={BootstrapInput}
          snapshots={snapshots}
          striperecurringprod={striperecurringprod}
          stripeProducts={stripeProducts}
          Purchasedapps={Purchasedapps}
          workflows={workflows}
          Catgories={Catgories}
          handleimage={handleimage}
        />
      );
    default:
      return null;
  }
}

function Wizard({
  workflows,
  BootstrapInput,
  handleimage,
  striperecurringprod,
  stripeProducts,
  Catgories,
  Purchasedapps,
  snapshots,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [Resale, setResale] = useState(false);
  const steps = getSteps(Resale);
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <MDBox pt={3}>
      <Grid container justifyContent="center">
        {activeStep == 0 ? (
          <Grid item md={12}>
            <MDBox mb={1}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  <MDTypography
                    variant="h6"
                    sx={{ fontSize: "17px", fontWeight: "400" }}
                    display="block"
                    gutterBottom
                  >
                    Is it resaleable ?
                  </MDTypography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name={"resale"}
                  sx={{ marginTop: "-6px" }}
                >
                  {Resale == false ? (
                    <>
                      <FormControlLabel
                        value="yes"
                        onClick={() => setResale(true)}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        onClick={() => setResale(false)}
                        control={<Radio />}
                        checked
                        label="No"
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value="yes"
                        checked
                        onClick={() => setResale(true)}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        onClick={() => setResale(false)}
                        control={<Radio />}
                        label="No"
                      />
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </MDBox>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          {/* <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <Stepper sx={{ margin: 0,background:'none' }} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox> */}
          <MDBox p={2}>
            <MDBox>
              {getStepContent({
                activeStep,
                workflows,
                BootstrapInput,
                handleimage,
                striperecurringprod,
                stripeProducts,
                Catgories,
                Purchasedapps,
                snapshots,
              })}
              <MDBox
                mt={5}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                {activeStep === 0 ? (
                  <MDBox />
                ) : (
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={handleBack}
                  >
                    back
                  </MDButton>
                )}
                {isLastStep ? (
                  <MDButton variant="gradient" color="dark" type="submit">
                    Save
                  </MDButton>
                ) : (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={!isLastStep ? handleNext : undefined}
                  >
                    next
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Wizard;
