/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "examples/Sidenav2/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Collapse } from "@mui/material";

function SidenavCollapse({
  icon,
  name,
  brand_data,
  active,
  children,
  noCollapse,
  open,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  // alert(brand_data?.app_domain?.colors?.button_background)
  const primary = "linear-gradient(195deg, #EC407A, #D81B60)";
  const dark = "linear-gradient(195deg, #42424a, #191919)";
  const info = "linear-gradient(195deg, #49a3f1, #1A73E8)";
  const success = "linear-gradient(195deg, #66BB6A, #43A047)";
  const warning = "linear-gradient(195deg, #FFA726, #FB8C00)";
  const error = "linear-gradient(195deg, #EF5350, #E53935)";
  const app_config = brand_data?.app_domain?.colors;
  if (app_config) {
    document.documentElement.style.setProperty(
      "--brand-list-item-bg",
      app_config?.button_background ? app_config?.button_background : info
    );
    document.documentElement.style.setProperty(
      "--brand-button-bg",
      app_config?.button_background ? app_config?.button_background : info
    );
    document.documentElement.style.setProperty(
      "--brand-button-text",
      app_config?.button_text ? app_config?.button_text : "#fff"
    );
    document.documentElement.style.setProperty(
      "--brand-card-text",
      app_config?.cards_text ? app_config?.cards_text : "#fff"
    );
    document.documentElement.style.setProperty(
      "--brand-card-bg",
      app_config?.cards_background ? app_config?.cards_background : info
    );
    document.documentElement.style.setProperty(
      "--brand-sidnav-list-hover",
      app_config?.sidnav_list_hover_color
        ? app_config?.sidnav_list_hover_color
        : "grey"
    );
  }

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>
                {icon}
              </Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {children && (
            <Icon
              sx={(theme) =>
                collapseArrow(theme, {
                  noCollapse,
                  transparentSidenav,
                  whiteSidenav,
                  miniSidenav,
                  open,
                  active,
                  darkMode,
                })
              }
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={Boolean(open)} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  brand_data: PropTypes.arrayOf(PropTypes.array).isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
