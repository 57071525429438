import Button from "@mui/material/Button";
import localforage from "localforage";
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Switch,
  TextareaAutosize,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Singleselect from "./components/inputs/Singleselect";
import MDTypography from "components/MDTypography";
import "./components/inputs/input.css";
import Alert from "@mui/material/Alert";
import { useBrandInfo } from "context/brand";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect } from "react";
import StripeLogo from "assets/images/logos/Stripe_Logosvg.png";
import creditcards from "assets/images/credit-cards.png";
import ghlLogo from "assets/images/ghl-logo.avif";
import Billing from "./components/Billing";
import env from "config";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
import { useIntegrationInfo } from "context/integration";
import { Navigate, useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import PaymentMethod from "./components/PaymentMethod";
import { useAppServices } from "hook/services";
const Integration = () => {
  const AppService = useAppServices();
  const [brand] = useBrandInfo();
  const [agency] = useAgencyInfo();
  const updateAgency = useAgencyInfo()[3];
  const [settings, setupValid, Update, setsetupValid] = useIntegrationInfo();
  const [processing, setProcessing] = useState(false);
  const [saved_data, setsaved_data] = useState("");
  const [agency_id, setagency_id] = useState("");
  const [domain, setdomain] = useState("");
  const [errormsg, seterrormsg] = useState("");
  const [response, setresponse] = useState("");
  const [app_setup, setapp_setup] = useState({});
  const [refresh, setrefresh] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customer, setCustomer] = useState({});
  const [loader, setloader] = useState(true);
  const [domainUpdate, setdomainUpdate] = useState(false);
  const [agency_stripe, setagency_stripe] = useState("");
  const [workflows_data, setworkflows_data] = useState([]);
  const [defeult_password, setdefeult_password] = useState([]);

  const navigate = useNavigate();
  const BASE_URL = `${env.API_URL}/v1`;
  const BASE_URL_V2 = `${env.API_URL}/v2`;
  const currency_data = [
    {
      id: "usd",
      name: "USD",
    },
    {
      id: "gbp",
      name: "GBP",
    },
    {
      id: "eur",
      name: "EUR",
    },
    {
      id: "cad",
      name: "CAD",
    },
    {
      id: "aud",
      name: "AUD",
    },
  ];
  const getStripe = async (e) => {
    const { response } = await AppService.stripe.filter({
      id: agency._id,
    });
    console.log(response, "responseresponse");
    if (
      response.data.stripe_data != null &&
      response.data.stripe_data != undefined
    ) {
      setagency_stripe(response.data.stripe_data);
    } else {
      setagency_stripe("");
    }
  };
  var axios = require("axios");
  const onload = async () => {
    console.log(agency, "settingssettings");
    seterrormsg("");
    setdomain("");
    updateAgency();
    getStripe();
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand?.agency_id);
    // alert(localBrand.stripe.customer_id)
    const response = await axios
      .get(
        BASE_URL_V2 +
          "/snapshot/agency/location_settings/filter/" +
          localBrand?.agency_id
      )
      .then((response) => {
        setsaved_data(response.data);
        // setloc_api_key(response.data.data.loc_api_key);
        setdomain(response.data.data.domain);
        setapp_setup(response.data.data);
        console.log(response.data.data, "test");
        if (
          response.data.data?.app_purchase != "" &&
          response.data.data?.app_purchase != undefined &&
          response.data.data?.invite_link != "" &&
          response.data.data?.invite_link != undefined &&
          response.data.data?.task_completed != "" &&
          response.data.data?.task_completed != undefined &&
          response.data.data?.purchase_message != "" &&
          response.data.data?.purchase_message != undefined &&
          response.data.data?.currency != "" &&
          response.data.data?.currency != undefined
        ) {
          // alert("done");
          setsetupValid(true);
          Update(response.data.data);
          navigate("/agency/dashboard");
        }
        console.log(response.data.data.domain);

        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    if (agency.ghl != undefined) {
      await axios
        .get(
          BASE_URL_V2 +
            "/snapshot/agency/location_settings/getworkflows/" +
            localBrand.agency_id
        )
        .then((response) => {
          console.log(response.data, "getworkflows");
          setworkflows_data(response.data.data.workflows.workflows || []);
          var temp_dpass = response.data.data.custom_fields.customFields.filter(
            function (item) {
              return item.dataType == "TEXT";
            }
          );

          temp_dpass.sort((a, b) => a.name.localeCompare(b.name));

          setdefeult_password(temp_dpass);
          setloader(false);
        })
        .catch(function (error) {
          console.log("error");
          setworkflows_data([]);
          setdefeult_password([]);
          setloader(false);
          setTimeout(() => {
            seterrormsg(
              "Your Auth Token Is Expired Please Refresh Your Token!"
            );
          }, 2009);
        });
    } else {
      seterrormsg("Your Auth Token Is Expired Please Refresh Your Token!");
      setloader(false);
    }
    if (agency.agency_ghl == undefined && agency.type != "free") {
      seterrormsg(
        "Your Agency Auth Token Is Not Connected Please Connect Your Agency!"
      );
    }
  };
  React.useEffect(() => {
    onload();
  }, [refresh]);

  var data = "";
  const handlemessage = (e) => {};
  const domain_manage = (e) => {
    setdomain(e);
    setdomainUpdate(true);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    // 4848 7150 5203 7927
    var currency_sign = "";
    if (e.target.currency.value == "gbp") {
      currency_sign = "£";
    } else if (e.target.currency.value == "eur") {
      currency_sign = "€";
    } else {
      currency_sign = "$";
    }
    data = {
      currency: e.target.currency.value,
      app_purchase: e.target.app_purchase.value,
      invite_link: e.target.invite_link.value,
      task_completed: e.target.task_completed.value,
      currency_sign: currency_sign,
      agency_id: agency_id,
      purchase_message: e.target.purchase_message.value,
    };
    console.log(data, "data");
    // return 0
    if (saved_data === "") {
      axios
        .post(BASE_URL_V2 + "/snapshot/agency/location_settings/submit", {
          data,
        })
        .then(async function (response) {
          setProcessing(false);
          console.log(response.data);
          setsaved_data(response.data);
          setrefresh(!refresh);
          setresponse("Setting Updated");
          setTimeout(() => {
            setresponse("");
          }, 2000);
        })
        .catch(function (error) {
          setProcessing(false);

          console.log(error.response.data);
          seterrormsg(error.response.data.message);
        });
    } else {
      axios
        .post(
          BASE_URL_V2 +
            "/snapshot/agency/location_settings/update/" +
            saved_data.data._id,
          {
            data,
          }
        )
        .then(async function (response) {
          console.log(response.data);
          setProcessing(false);

          setrefresh(!refresh);
          await setresponse(response.data.message);
          setTimeout(() => {
            setresponse("");
          }, 2200);
          setsaved_data(response.data);
        })
        .catch(function (error) {
          setProcessing(false);
          console.log(error.response.data);
          seterrormsg(error.response.data.message);
        });
    }
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <MDBox pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container mt={5} mb={2} justifyContent="center">
                <Stack sx={{ width: "90%" }} spacing={2} mb={2}>
                  {errormsg != "" ? (
                    <Alert severity="error">{errormsg}</Alert>
                  ) : (
                    <></>
                  )}
                  {response != "" ? (
                    <Alert severity="success">{response}</Alert>
                  ) : (
                    <></>
                  )}
                </Stack>
                <Grid item xs={11}>
                  <MDBox display="flex">
                    <Grid item xs={3}>
                      <img src={StripeLogo} alt="logo" width={140} />
                      <Billing />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      bgcolor={"#F0F2F5"}
                      sx={{ backgroundColor: "#F0F2F5" }}
                    >
                      <MDBox
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#F0F2F5",
                        }}
                        bgcolor={"#F0F2F5"}
                      >
                        <img src={ghlLogo} alt="logo" width={100} />

                        {agency?.agency_ghl != undefined ? (
                          <MDButton
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{ width: "100px", backgroundColor: "#F0F2F5" }}
                          >
                            <a
                              href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.APPSCOPE}&state=${brand.ghl?.location_id}`}
                              style={{ color: "white" }}
                            >
                              Agency Refresh
                            </a>
                          </MDButton>
                        ) : (
                          <MDButton
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{ width: "100px" }}
                          >
                            <a
                              href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.AGENCY}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.APPSCOPE}&state=${brand.ghl?.location_id}`}
                              style={{ color: "white" }}
                            >
                              Agency connect
                            </a>
                          </MDButton>
                        )}
                      </MDBox>
                    </Grid>

                    <MDBox sx={{ display: "flex", flexDirection: "column" }}>
                      <img src={ghlLogo} alt="logo" width={100} />

                      {agency?.ghl != undefined ? (
                        <MDButton
                          variant="contained"
                          color="success"
                          size="small"
                          sx={{ width: "100px" }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.Location_ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                            style={{ color: "white" }}
                          >
                            Location Refresh
                          </a>
                        </MDButton>
                      ) : (
                        <MDButton
                          variant="contained"
                          color="info"
                          size="small"
                          sx={{ width: "100px" }}
                        >
                          <a
                            href={`https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.Location_ASSOCIATE}&client_id=${env.GHL.Location_CLIENT_ID}&scope=${env.GHL.Location_SCOPE}&state=${brand.ghl?.location_id}`}
                            style={{ color: "white" }}
                          >
                            Location connect
                          </a>
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>

                  <MDBox pt={4} px={3}>
                    <form onSubmit={handlesubmit}>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Select Currency
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={currency_data}
                            edit_data={app_setup?.currency || ""}
                            name="currency"
                            label="currency"
                          />
                        </MDBox>
                      </div>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when a
                          customer makes a purchase?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={app_setup?.app_purchase || ""}
                            name="app_purchase"
                            label="App Purchase"
                          />
                        </MDBox>
                      </div>

                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which field would you like store their Invite Link to?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                          mb={1}
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={defeult_password}
                            edit_data={app_setup?.invite_link || ""}
                            name="invite_link"
                            label="Invite Link"
                          />
                        </MDBox>
                      </div>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          What Message would you like to Show when a customer
                          makes a purchase?
                        </MDTypography>
                        {/* <MDTypography
                        id="modal-modal-title"
                        variant="caption"
                        component="h2"
                      >
                        must complete*
                         </MDTypography> */}
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={1}
                            className="form-control"
                            defaultValue={app_setup?.purchase_message}
                            name="purchase_message"
                            placeholder="description"
                            style={{ width: "100%", paddingLeft: "13px" }}
                          />
                        </MDBox>
                      </div>
                      <div>
                        <MDTypography
                          id="modal-modal-title"
                          sx={{ mt: 4 }}
                          variant="h6"
                          component="h2"
                        >
                          Which workflow would you like to trigger when you load
                          snapshot for the customer?
                        </MDTypography>
                        <MDTypography
                          id="modal-modal-title"
                          variant="caption"
                          component="h2"
                        >
                          must complete*
                        </MDTypography>
                        <MDBox sx={{ mt: 1, fontSize: "16px" }}>
                          <Singleselect
                            data={workflows_data}
                            edit_data={app_setup?.task_completed || ""}
                            name="task_completed"
                            label="App Purchase"
                          />
                        </MDBox>
                      </div>
                      <div style={{ marginTop: "2rem" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {saved_data === "" ? (
                            <>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                loading={processing}
                                disabled={processing}
                              >
                                Save
                              </MDButton>
                            </>
                          ) : (
                            <>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                loading={processing}
                                disabled={processing}
                              >
                                Update
                              </MDButton>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </MDBox>
                </Grid>
                <Grid item xs={11}>
                  <MDBox pt={4} px={3}>
                    <MDTypography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Connect white label domain
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      Connect your own domain to the system (without https://)
                      levelupmarketplace.yourdomain.com
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      You can use any domain you own or you can create a
                      sub-domain like levelupmarketplace.yourdomain.com to get
                      white-labeled access to the platform.
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>1.</b> Refer to your domain registrar's control panel,
                      choose desired domain zone.
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>2.</b>Create a record of{" "}
                      <u>
                        <b>ANAME</b>
                      </u>{" "}
                      type, enter your domain/subdomain name and point it to{" "}
                      <u>
                        <b>167.71.131.180</b>
                      </u>
                    </MDTypography>
                    <MDTypography
                      variant="caption"
                      display="block"
                      sx={{ fontSize: "15px" }}
                      gutterBottom
                    >
                      <b>3.</b> We will do the rest!
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  );
};

export default Integration;
