/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useState, useMemo, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import localforage from "localforage";
import { useAppServices } from "hook/services";
import Loader from "examples/Loader";
import {
  setDarkMode,
  setFixedNavbar,
  setSidenavColor,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";

// Material Dashboard 2 React main context
const IntegrationContext = createContext();

// Setting custom name for the context which is visible on react dev tools
IntegrationContext.displayName = "IntegrationContext";

// Material Dashboard 2 React context provider
function IntegrationProvider({ children }) {
  const Service = useAppServices();
  const [settings, setSettings] = useState({});
  const [loader, setLoader] = useState(true);
  const [setupValid, setsetupValid] = useState(false);
  const [checkonborad, setcheckonborad] = useState("/integration");

  // Theme Functions

  const Update = (updates) => {
    const data = { ...settings, ...updates };
    setSettings(data);
  };

  const clear = () => setSettings({});

  const value = useMemo(
    () => [settings, setupValid, Update, setsetupValid, clear, checkonborad],
    [settings, setupValid, Update, setsetupValid, clear, checkonborad]
  );

  const getIntegration = async () => {
    const token = await localforage.getItem("token");
    const localAgency = await localforage.getItem("agency");
    if (!localAgency) return setLoader(false);
    const { response } = await Service.location_settings.get({
      id: localAgency._id,
      token,
    });
    console.log(localAgency, "settingResponse");
    if (!response) return setLoader(false);
    setSettings({ ...response.data });
    if (
      response.data.data?.app_purchase != "" &&
      response.data.data?.app_purchase != undefined &&
      response.data.data?.invite_link != "" &&
      response.data.data?.invite_link != undefined &&
      response.data.data?.task_completed != "" &&
      response.data.data?.task_completed != undefined &&
      response.data.data?.purchase_message != "" &&
      response.data.data?.purchase_message != undefined &&
      response.data.data?.currency != "" &&
      response.data.data?.currency != undefined
    ) {
      // alert('dashboard')
      // setcheckonborad("/agency/dashboard");
      setsetupValid(true);
    } else {
      setsetupValid(false);
    }

    return setLoader(false);
  };

  const onLoad = () => {
    getIntegration();
  };

  useEffect(onLoad, []);

  return loader ? (
    <Loader />
  ) : (
    <IntegrationContext.Provider value={value}>
      {children}
    </IntegrationContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function useIntegrationInfo() {
  return useContext(IntegrationContext) || [];
}

// Typechecking props for the MaterialUIControllerProvider
IntegrationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { IntegrationProvider, useIntegrationInfo };
