import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import { useFormik } from "formik";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import localforage from "localforage";
import env from "config";
import { TextareaAutosize } from "@mui/material";
const Category_Modal = (props) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [agency_id, setagency_id] = useState();
  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(async () => {
    console.log(props);
    const localBrand = await localforage.getItem("user");
    setagency_id(localBrand.agency_id);
  }, [props.refresh]);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
        var axios = require("axios");

        setProcessing(true);
        const name = e.target.name.value;
        const description = e.target.description.value;
        var data = "";
        data = {
          name: name,
          description: description,
          agency_id: agency_id,
          created_by: "seller",
        };
        console.log(data);
        axios
          .post(BASE_URL + "/snapshot/category/submit", { data })
          .then(async function (response) {
            console.log(response.data);
            setOpenAddProduct(false);
            props.handleChange(true);
            props.handlemessag("category create successfully", "success");
          })
          .catch(function (error) {
            console.log(error);
            props.handlemessag("category not create successfully", "false");
          });
        // const { response } = await AppService.stripe.addSubscription({ payload })

        // setProcessing(false)
        // onClose(response?.data)
    
    };
    const handleSelectChange = (e, { value }, name) => {
      formik.setValues({ ...formik.values, [name]: value });
    };

    const formik = useFormik({
      initialValues: { ...initState },
      onSubmit: handleSubmit,
    });
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Category
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDInput type="name" label="Name" name="name" fullWidth />
              </MDBox>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={1}
                className="form-control"
                name="description"
                placeholder="description"
                style={{ width: "100%", paddingLeft: "13px" }}
              />
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Category
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Category_Modal;
