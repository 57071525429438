/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useState, useMemo, useEffect } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import localforage from 'localforage'
import { useAppServices } from 'hook/services'
import Loader from 'examples/Loader'
// import { setDarkMode, setFixedNavbar, setSidenavColor, setTransparentSidenav, setWhiteSidenav, useMaterialUIController } from 'context'

// Material Dashboard 2 React main context
const BrandContext = createContext()

// Setting custom name for the context which is visible on react dev tools
BrandContext.displayName = 'BrandContext'

// Material Dashboard 2 React context provider
function BrandProvider({ children }) {
  // const [,dispatch]         = useMaterialUIController()
  const Service             = useAppServices()
  const [brand, setBrand]   = useState({})
  const [loader, setLoader] = useState(true)

  // Theme Functions
  // const handleThemeUpdates = updates => {
  //   setSidenavColor(dispatch, updates.sideNavColor)
  //   setWhiteSidenav(dispatch, updates.sideNavType == 'white')
  //   setTransparentSidenav(dispatch, updates.sideNavType == 'transparent')
  //   setFixedNavbar(dispatch, updates.navbarFixed)
  //   setDarkMode(dispatch, !updates.light)
  // }

  const Update = (updates) => setBrand({...brand, ...updates})

  const clear = () => setBrand({})

  const value = useMemo(() => [brand, Update, clear], [brand, Update, clear])

  const getBrand = async localBrand => {
    const token = await localforage.getItem('token')
    const { response } = await Service.brand.get({query: `_id=${localBrand._id}`, token})
    if(!response) 
    return setLoader(false)
    
    setBrand({...response.data})
    return localforage.setItem('brand', response.data)
  }

  const updateBrand = async () => {
    const localBrand = await localforage.getItem('brand')
    if(!localBrand) return setLoader(false)
    setBrand({...localBrand})
    setLoader(false)
    return getBrand(localBrand)
  }

  const onLoad = () => {
    updateBrand()
  }

  useEffect(onLoad, [])

  return loader ? <Loader /> : <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
}

// Material Dashboard 2 React custom hook for using context
function useBrandInfo() {
  return useContext(BrandContext) || []
}

// Typechecking props for the MaterialUIControllerProvider
BrandProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { BrandProvider, useBrandInfo }
