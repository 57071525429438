import DashboardLayout from "examples/LayoutContainers/AgencyDashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import localforage from "localforage";
import MUIDataTable from "mui-datatables";
import moment from "moment";
// import "../Users/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import PurchasesDetailsModal from "./Components/PurchasesDetailsModal";
import { useUserInfo } from "context/user";
import { useLocation } from "react-router-dom";

function Purchases() {
  const Service = useAppServices();
  const [agency] = useAgencyInfo();
  const [user] = useUserInfo();
  // const [user, setuser] = useState([]);
  const [Conjo, setConjo] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [errormsg, seterrormsg] = useState("");
  const [snapshot, setsnapshot] = useState([]);
  const [snapshot_Select_data, setsnapshot_Select_data] = useState([]);
  const [locations_data, setlocations_data] = useState([]);
  const [charge_data, setcharge_data] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const location_settings = useAgencyInfo()[4];
  const { pathname } = useLocation();
  var type = decodeURIComponent(pathname.split("/")[3]);

  const [dateFilterChecked, setDateFilterChecked] = useState(true);

  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },

    {
      name: "Type",
      options: {
        filter: false,
      },
    },
    {
      name: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "User",
      options: {
        filter: false,
      },
    },
    {
      name: "Date",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Location",
    //   options: {
    //     filter: false,
    //   },
    // },
    // {
    //   name: "Location Address",
    //   options: {
    //     filter: false,
    //   },
    // },
    {
      name: "View Details",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getPurchases = async () => {
    const { response } = await Service.bundles.get_purchases({
      query: `seller_id=${agency._id}`,
    });
    console.log(response, "response");
    if (response) {
      var tabel_data = [];
      response.data?.forEach((element) => {
        if (
          element.purchase_data?.type == type ||
          type == "all" ||
          type == "resalling"
        ) {
          const temp = [
            element.user_data?.username,
            element.purchase_data?.type,
            location_settings.currency_sign + element?.purchase_data?.price ||
              "0",
            element.user_data?.email,
            element.purchase_data?.createdAt,
            // element_p?.loc_name,
            // element_p?.loc_add,
            <PurchasesDetailsModal data={element} />,
          ];
          tabel_data.push(temp);
        }
      });
      // var snapshot_data = [];
      // response?.snapshot?.forEach((element) => {
      //   // console.log(element,'snapshot')
      //   var purchase_listener = "";
      //   if (element.payable == "yes") {
      //     purchase_listener = parseFloat(element.purchase_listener);
      //   } else {
      //     purchase_listener = 0;
      //   }
      //   const temp = {
      //     label: element.name,
      //     value: element._id,
      //     price: purchase_listener,
      //   };
      //   snapshot_data.push(temp);
      // });
      // var user_data = [];
      // response?.user?.forEach((element) => {
      //   // console.log(element,'user_data')
      //   if (element.roles.includes("admin")) {
      //     const temp = {
      //       label: element.username,
      //       value: element._id,
      //     };
      //     user_data.push(temp);
      //   }
      // });
      // var locations_data = [];
      // response?.locations?.forEach((element) => {
      //   // console.log(element,'locations_data')

      //   const temp = {
      //     label: element.name,
      //     value: element.id,
      //   };
      //   locations_data.push(temp);
      // });
      // var temp_data = {
      //   snapshot_data: snapshot_data,
      //   user_data: user_data,
      //   locations_data: locations_data,
      // };
      // setsnapshot(response.snapshot);
      // setcharge_data(temp_data);
      // console.log(temp_data, "temp_datatemp_data");
      // setsnapshot_Select_data(snapshot_data);
      // setuser(user_data);
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    setProcessing(true);
    // alert(type);
    getPurchases();
  };
  useEffect(async () => {
    onLoad();
  }, [pathname]);
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar
            type="purchases"
            charge_data={charge_data}
            handleChange={onLoad}
          />
          <Grid container>
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
            {errormsg != "" ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{errormsg}</Alert>
              </Stack>
            ) : (
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Purchases"}
                  data={Conjo}
                  columns={columns}
                  options={options}
                />
              </Grid>
            )}
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Purchases;
