/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import timezones from "timezones.json";
// Settings page components
import FormField from "./FormField";
import { useEffect, useState } from "react";
// Data
import selectData from "./data/selectData";
import Multiselect from "./data/Multiselect";
import MDAvatar from "components/MDAvatar";
import { useAppServices, useUploadImage } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { Stack, Button } from "@mui/material";
// import Logo_img from "assets/images/Grey_full.png";
import MDInput from "components/MDInput";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
function BasicInfo() {
  const uploadImage = useUploadImage();
  const AppService = useAppServices();
  const [agency, Update] = useAgencyInfo();
  const [timezones_data, settimezones_data] = useState([]);
  const [agency_data, setagency_data] = useState([]);
  const [image_url, setimage_url] = useState(agency?.profile?.image_url);
  // const [image_url, setimage_url] = useState(
  //   agency?.basic_info?.image_url || Logo_img
  // );
  const [bussiness_name, setbussiness_name] = useState(
    agency?.profile?.bussiness_name
  );
  const handleImage = async (e) => {
    const response = await uploadImage({
      file: e.target.files[0],
      desiredPath: `design/logo/image`,
    });
    setimage_url(response.response.data);
    console.log(response.response.data, "logo");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const profile = {
      facebook: e.target.facebook.value,
      instagram: e.target.instagram.value,
      linkedin: e.target.linkedin.value,
      twitter: e.target.twitter.value,
      email: e.target.email.value,
      bussiness_name: e.target.bussiness_name.value,
      company_webiste: e.target.company_webiste.value,
      phone_number: e.target.phone_number.value,
      address: e.target.address.value,
      city: e.target.city.value,
      zip_code: e.target.zip_code.value,
      time_zone: JSON.parse(e.target.time_zone.value),
      country: e.target.country.value,
      state: e.target.state.value,
      image_url: image_url,
    };
    const payload = {
      _id: agency._id,
      profile: profile,
    };
    console.log(payload, "payload");

    const { response } = await AppService.agency.update({
      payload,
      toaster: true,
    });
    if (response) {
      Update(response.data);
    }

    console.log(response, "response");
  };
  useEffect(async () => {
    setagency_data(agency);
    var temp_time = [];
    for (let index = 0; index < timezones.length; index++) {
      const element = timezones[index];

      var temp = {
        label: element.text,
        value: element.value,
      };
      temp_time.push(temp);
    }
    settimezones_data(temp_time);
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="profile" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar
                src={image_url}
                alt="profile-image"
                size="xl"
                shadow="sm"
                className="comapny-logo"
              />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {bussiness_name}
                </MDTypography>
                {/* <MDTypography variant="button" color="text" fontWeight="medium">
         
          </MDTypography> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
              <MDBox
                display="flex"
                justifyContent={{ md: "flex-end" }}
                alignItems="center"
                lineHeight={1}
              >
                <MDBox ml={1}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ color: "#fff" }}
                    >
                      Upload Logo +
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleImage}
                      />
                    </Button>
                  </Stack>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>

      <Card id="basic-info" sx={{ overflow: "visible", mt: 3 }}>
        <MDBox p={3}>
          <MDTypography variant="h5">Bussiness Profile </MDTypography>
        </MDBox>

        <MDBox component="form" onSubmit={handleSubmit}>
          <Grid container spacing={3} paddingX={3} paddingBottom={4}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                placeholder="example@email.com"
                inputProps={{ type: "email" }}
                name="email"
                defaultValue={agency_data?.profile?.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                variant="standard"
                label={"Bussiness Name"}
                value={bussiness_name}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setbussiness_name(e.target.value)}
                name={"bussiness_name"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Company Website"
                placeholder="Company Website"
                name="company_webiste"
                defaultValue={agency_data?.profile?.company_webiste}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                placeholder="+40 735 631 620"
                inputProps={{ type: "phone" }}
                name={"phone_number"}
                defaultValue={agency_data?.profile?.phone_number}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormField
                label="Address"
                placeholder="Address"
                name="address"
                defaultValue={agency_data?.profile?.address}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="City"
                placeholder="City"
                name="city"
                defaultValue={agency_data?.profile?.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Zip Code"
                placeholder="Zip Code"
                name="zip_code"
                defaultValue={agency_data?.profile?.zip_code}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <MDTypography
                  variant="body2"
                  display="block"
                  gutterBottom
                  sx={{ fontSize: "13px", fontWeight: "400", color: "#7b809a" }}
                >
                  Time Zone*
                </MDTypography>
                <Multiselect
                  data={timezones_data}
                  name="time_zone"
                  edit_data={agency_data?.profile?.time_zone}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Country"
                placeholder="Country"
                name="country"
                defaultValue={agency_data?.profile?.country}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="State / Prov / Region *"
                placeholder="State / Prov / Region *"
                name="state"
                defaultValue={agency_data?.profile?.state}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MDTypography variant="h5">Sociel Links</MDTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Facebook"
                placeholder="Facebook"
                name="facebook"
                defaultValue={agency_data?.profile?.facebook}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Instagram"
                placeholder="Instagram"
                name="instagram"
                defaultValue={agency_data?.profile?.instagram}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="LinkedIn"
                placeholder="LinkedIn"
                name="linkedin"
                defaultValue={agency_data?.profile?.linkedin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Twitter"
                placeholder="Twitter"
                name="twitter"
                defaultValue={agency_data?.profile?.twitter}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
              <MDButton variant="gradient" color="info" type="submit">
                update
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default BasicInfo;
