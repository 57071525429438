import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useRef, useState, useEffect } from "react";
import MDModal from "components/MDModal";
import MDBox from "components/MDBox";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import localforage from "localforage";
import MDTypography from "components/MDTypography";
import "./modal.css";
import { useMediaQuery, useTheme } from "@mui/material";
import env from "config";
import { useAgencyInfo } from "context/agency";
import Wrappar from "./Wrappar";
const Snapshot_modal = (props) => {
  console.log(props.item, "propspropsprops");
  const [agency] = useAgencyInfo();
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [refresh, setrefresh] = useState(false);

  var stripeProducts = props.stripeProducts.filter(function (item) {
    return item.type != "recurring";
  });
  var striperecurringprod = props.stripeProducts.filter(function (item) {
    return item.type == "recurring";
  });
  console.log(striperecurringprod, "striperecurringprod");
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.down("md"));
  var image_file = "";
  var loc_image_file = "";
  const BASE_URL = `${env.API_URL}/v1`;

  function AddProduct({ open, onClose }) {
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
        border: "1px solid #ced4da",
        fontSize: 16,
        width: "100%",
        padding: "10px 12px",
        transition: theme.transitions.create([
          "border-color",
          "background-color",
          "box-shadow",
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        "&:focus": {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    }));
    const handleimage = async (e, type) => {
      console.log(e, type, "imagehandler");
      if (type == "selling") {
        loc_image_file = e.target.files[0];
      } else {
        image_file = e.target.files[0];
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        e.target.iframe_url.value.substr(0, 5) == "https" &&
        e.target.doc.value.substr(0, 5) == "https"
      ) {
        var axios = require("axios");
        var loc_img_data = "";
        if (loc_image_file != "") {
          alert(loc_image_file);
          const loc_form_data = new FormData();
          loc_form_data.append("image", loc_image_file, loc_image_file.name);
          console.log(loc_form_data.get("image"));

          loc_img_data = await axios
            .post(`${BASE_URL}/utils/upload/image`, loc_form_data)
            .then((response) => {
              setOpenAddProduct(false);
              return response.data.data;
            });
        } else {
          loc_img_data = props.item.loc_image_url;
        }
        var img_data = "";
        var axios = require("axios");
        if (image_file != "") {
          const form_data = new FormData();
          form_data.append("image", image_file, image_file.name);
          console.log(form_data.get("image"));

          img_data = await axios
            .post(BASE_URL + "/utils/upload/image", form_data)
            .then((response) => {
              // console.log(response.data,'image_data');

              return response.data.data;
            });
        } else {
          img_data = props.item.image_url;
        }
        const localBrand = await localforage.getItem("user");
        const agency_id = localBrand.agency_id;
        const name = e.target.name.value;
        const lockfield = JSON.parse(e.target.lockfield.value);
        const category = e.target.category.value.split(",");
        var snapshot = [];
        var snapshot_string = [];
        var snapshot = JSON.parse(e.target.snapshot.value.split(","));
        // if (agency.type != "free") {
        // } else {
        //   var snapshot_string = e.target.snapshot_string.value.split(",");
        // }

        const setup_payable = e.target.setup_payable.value;
        const setup_fee = e.target.setup_fee.value;
        const management_payable = e.target.management_payable.value;
        const management_sub = e.target.management_sub.value;

        const cost_setup_payable = e.target.cost_setup_payable.value;
        const cost_setup_fee = e.target.cost_setup_fee.value;
        const cost_management_payable = e.target.cost_management_payable.value;
        const cost_management_sub = e.target.cost_management_sub.value;

        const selling_setup_payable = e.target.selling_setup_payable.value;
        const selling_setup_fee = e.target.selling_setup_fee.value;
        const selling_management_payable =
          e.target.selling_management_payable.value;
        const selling_management_sub = e.target.selling_management_sub.value;

        const apps = e.target.apps.value.split(",");
        const iframe_url = e.target.iframe_url.value;
        const workflows = e.target.workflows.value;
        const doc = e.target.doc.value;
        const description = e.target.description.value;
        const image =
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsxkI07nHexe_9vS8jw7OKUzSFQzTuZv77VA&usqp=CAU";
        const data = {
          name: name,
          setup_payable: setup_payable,
          management_payable: management_payable,
          setup_fee: setup_fee,
          management_sub: management_sub,

          cost_setup_payable: cost_setup_payable,
          cost_management_payable: cost_management_payable,
          cost_setup_fee: cost_setup_fee,
          cost_management_sub: cost_management_sub,

          selling_setup_payable: selling_setup_payable,
          selling_management_payable: selling_management_payable,
          selling_setup_fee: selling_setup_fee,
          selling_management_sub: selling_management_sub,
          lockfield: lockfield,
          doc: doc,
          loc_doc: e.target.loc_doc.value,
          loc_name: e.target.loc_name.value,
          loc_description: e.target.loc_description.value,
          loc_iframe_url: e.target.loc_iframe_url.value,
          resellable: e.target.resellable.value,
          apps: JSON.parse(apps),
          description: description,
          category: JSON.parse(category),
          snapshot: snapshot,
          snapshot_string: snapshot_string,
          iframe_url: iframe_url,
          loc_image_url: loc_img_data,
          workflows: workflows,
          image_url: img_data,
          agency_id: agency_id,
          type: "bundle",
        };
        console.log(data, "data");
        axios
          .post(BASE_URL + "/seller/bundle/update/" + props.item._id, { data })
          .then(async function (response) {
            console.log(response.data);
            props.handleChange(true);
            props.handleRefresh(true);
            props.handlemessag("snapshot edit successfully", "success");
          })
          .catch(function (error) {
            console.log(error);
            props.handlemessag("snapshot not edit successfully", "false");
          });
      } else {
        alert("please check your url make sure it will be start with https");
      }
      setOpenAddProduct(false);

      // // const { response } = await AppService.stripe.addSubscription({ payload })
    };
    return (
      <MDModal
        open={open}
        onClose={onClose}
        width={600}
        height={"auto"}
        overflow={"auto"}
        className="css-1stqary"
      >
        <MDBox>
          <MDTypography component="h1" mb={1} variant="h5">
            Create Bundle
          </MDTypography>

          <MDBox
            component="form"
            role="form"
            sx={{ width: 535 }}
            onSubmit={handleSubmit}
          >
            <Wrappar
              BootstrapInput={BootstrapInput}
              snapshots={props.snapshots}
              item={props.item}
              striperecurringprod={striperecurringprod}
              stripeProducts={stripeProducts}
              Purchasedapps={props.Purchasedapps}
              workflows={props.workflows}
              Catgories={props.Catgories}
              handleimage={handleimage}
            />
            {/* <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 1, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }

  const closeAddProduct = (subscription) => {
    //   if (subscription?._id) updateTable(subscription)
    setOpenAddProduct(false);
  };
  const handleClick = () => {
    setOpenAddProduct(true);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        onClick={handleClick}
      >
        Edit
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default Snapshot_modal;
