import { FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import SubscriptionPayable from "./components/subscriptionpayable";
import Payable from "./components/Payable";
import Multiselect from "../Multi_Selecte/Multiselect";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useAgencyInfo } from "context/agency";
import TagsInput from "components/TagsInput";

const Wrappar = ({
  workflows,
  BootstrapInput,
  handleimage,
  striperecurringprod,
  stripeProducts,
  Catgories,
  Purchasedapps,
  snapshots,
}) => {
  const [agency] = useAgencyInfo();
  return (
    <>
      <Grid item md={12}>
        <MDBox mb={1}>
          <MDTypography variant="button" display="block" gutterBottom>
            What is your bundle called?
          </MDTypography>
          <MDBox mb={1} display={"flex"}>
            <BootstrapInput
              defaultValue=""
              id="bootstrap-input"
              fullWidth
              placeholder="name"
              name="name"
            />
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item md={12}>
        <MDBox mb={1} >
          <MDTypography variant="button" display="block" gutterBottom>
            Add your bundle description.
          </MDTypography>
          <MDBox display={"flex"}>

          <TextareaAutosize
            aria-label="minimum height"
            minRows={1}
            className="form-control"
            name="description"
            placeholder="description"
            style={{ width: "100%", paddingLeft: "13px" }}
          />
          </MDBox>
        </MDBox>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={8}>
          <MDTypography variant="button" display="block" gutterBottom>
            Upload a cover image for your bundle
          </MDTypography>
          <MDBox py={2}>
            <input
              type="file"
              name="image"
              className="form-control h-1"
              onChange={(e)=>handleimage(e,'basic')}
            />

            <p className="img_text">
              Upload a 640 x 360 pixel PNG, GIF, or JPG.
            </p>
          </MDBox>
        </Grid>
        <Grid item md={4} mt={1} className="img_adj" display={'flex'} justifyContent={'flex-end'}>
          <img
            src="https://via.placeholder.com/100"
            width={80}
            height={80}
            alt=""
          />
        </Grid>
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          <span sx={{ display: "none" }}>
            What categories does your bundle belong to?
          </span>
        </MDTypography>
        <MDBox mb={1} sx={{ fontSize: "14px" }}>
          <Multiselect data={Catgories} name="category" />
        </MDBox>
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          <span sx={{ display: "none" }}>what apps are included</span>
        </MDTypography>
        <MDBox mb={1} sx={{ fontSize: "14px" }}>
          <Multiselect data={Purchasedapps} name="apps" />
        </MDBox>
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          what snaphots are included
        </MDTypography>
        {agency.type != "free" ? (
          <MDBox mb={1}  width='100%' display="flex">
            <MDBox sx={{ fontSize: "14px" }} width='100%'>

            <Multiselect data={snapshots} name="snapshot" />
            </MDBox>
          </MDBox>
        ) : (
          <MDBox mb={1} sx={{ fontSize: "14px" }} display='flex'>
            <TagsInput name="snapshot_string" />
          </MDBox>
        )}
      </Grid>
      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          What is the URL that tells customers about your bundle?
        </MDTypography>
        <MDBox mb={1} display="flex">
          <BootstrapInput
            defaultValue=""
            id="bootstrap-input"
            fullWidth
            placeholder="https://www.example.com/"
            name="iframe_url"
          />
        </MDBox>
      </Grid>

      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          What is the URL with documentation about this bundle?
        </MDTypography>
        <MDBox mb={1} display="flex">
          <BootstrapInput
            defaultValue=""
            id="bootstrap-input"
            fullWidth
            placeholder="https://www.example.com/"
            name="doc"
          />
        </MDBox>
      </Grid>
      {/* <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
        How much is for this?
        </MDTypography>
        <MDBox mb={1} display="flex">
          <BootstrapInput
            defaultValue=""
            id="bootstrap-input"
            fullWidth
            type="number"
            placeholder=""
            name="price"
          />
        </MDBox>
      </Grid> */}
      <Payable
        data={stripeProducts}
        edit_data={""}
        payablename="setup_payable"
        label="is there a setup fee"
        name={"setup_fee"}
      />
      <SubscriptionPayable
        data={striperecurringprod}
        edit_data={""}
        payablename="management_payable"
        label="is there management subscriptions"
        name={"management_sub"}
      />

      <Grid item md={12}>
        <MDTypography mb={1} variant="button" display="block" gutterBottom>
          When a customer purchases the bundle what workflow do you want to
          trigger?
        </MDTypography>
        <MDBox mb={1} sx={{ fontSize: "14px" }}>
          <FormControl sx={{ m: 0, minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              workflows
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              autoWidth
              name="workflows"
              label="workflows"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {workflows.map((workflows) => (
                <MenuItem value={workflows.value} key="">
                  {workflows.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      </Grid>
    </>
  );
};

export default Wrappar;
