import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import "./Components/style.css";
import Loader from "examples/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import env from "config";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import MDButton from "components/MDButton";
import { useUserInfo } from "context/user";
import CompleteModal from "./Components/CompleteModal";

function Agencies() {
  const Service = useAppServices();
  const [agency] = useAgencyInfo();
  const [user] = useUserInfo();
  const [Conjo, setConjo] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [errormsg, seterrormsg] = useState("");
  const [snapshot, setsnapshot] = useState([]);
  const [snapshot_Select_data, setsnapshot_Select_data] = useState([]);
  const [locations_data, setlocations_data] = useState([]);
  const [charge_data, setcharge_data] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const location_settings = useAgencyInfo()[4];

  const [dateFilterChecked, setDateFilterChecked] = useState(true);

  const handlerefresh = async (e) => {
    setrefresh(!refresh);
  };

  const handleStatus = async (id) => {
    // setrefresh(!refresh);
    const payload = {
      _id: id,
      status: "completed",
    };
    console.log(payload);
    const { response } = await Service.bundles.updatetask({ payload });
    console.log(response);
    onLoad();
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "Created At",
      options: {
        filter: false,
      },
    },
    {
      name: "No Of Products",
      options: {
        filter: false,
      },
    },
    {
      name: "Revenue",
      options: {
        filter: false,
      },
    },
    // {
    //   name: "Action",
    //   options: {
    //     filter: false,
    //   },
    // },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getPurchases = async () => {
    console.log(location_settings, "location_settingslocation_settings");
    const { response } = await Service.marketplace.getCustomers({
      query: `seller_agency_id=${agency._id}`,
    });
    console.log(response, "getPurchasesgetPurchasesgetPurchasesgetPurchases");
    if (response) {
      var tabel_data = [];
      response.data?.forEach((element) => {
        const temp = [
          element.customer.name,
          element.user_data?.email,
          element.customer?.createdAt,
          element.purchased_prod?.count,
          element.purchased_prod?.amount_spend,
        ];
        tabel_data.push(temp);
        // if (element.purchase_data?.status == "pending") {
        // }
      });
      setConjo(tabel_data);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  const onLoad = () => {
    getPurchases();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <DashboardLayout>
          <DashboardNavbar
            type="tasks"
            charge_data={charge_data}
            handleChange={onLoad}
          />
          <Grid container>
            {/* <iframe src="https://snapshotstore.io/coming-soon" style={iframeStyles}></iframe> */}
            {errormsg != "" ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{errormsg}</Alert>
              </Stack>
            ) : (
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Agencies"}
                  data={Conjo}
                  columns={columns}
                  options={options}
                />
              </Grid>
            )}
          </Grid>
        </DashboardLayout>
      )}
    </>
  );
}

export default Agencies;
