/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput"
import { useEffect,useState } from "react"
import TextField from '@mui/material/TextField';
import { useRef } from "react"
function FormField({ name,defaultValue,handleChange,type,label,style, ...rest }) {
  const [edit_values, setedit_values] = useState('')
  const handleValue=(value)=>{
    setedit_values(value)
    // handleChange(value)
  }
  useEffect(async () => {
    setedit_values(defaultValue)
    console.log(style,'style')
  }, [defaultValue])
  return (
    <>
    <MDInput
    style={style}
      variant="standard"
      label={label}
      value={edit_values}
      fullWidth
      InputLabelProps={{ shrink: true }}
      onChange={(e)=>handleValue(e.target.value)}
      {...rest}
      name={name}
    />
    
    <input type={'hidden'} name=""/>
    </>
  )
}

// Setting default values for the props of FormField
FormField.defaultProps = {
  label: " ",
}

// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
  name:PropTypes.string,
  defaultValue:PropTypes.string,
  type:PropTypes.string,
  handleChange:PropTypes.func,
  style:PropTypes.object,
  

}

export default FormField
