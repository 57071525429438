/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";

function OrderSummary({ data }) {
  const location_settings = useAgencyInfo()[4];

  var profit = 0;
  if (data.config_app_type == "basic") {
    profit =
      parseFloat(data.price) -
      parseFloat(
        data.app.basic.value.substr(0, data.app.basic.value.indexOf(" "))
      );
  } else {
    profit = parseFloat(data.price);
  }
  return data.purchase_data?.type == "service" ? (
    <>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Summary
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Price:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            {location_settings.currency_sign}
            {data.purchase_data?.price}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  ) : (
    <>
      <MDBox mb={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Summary
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Management Subscription:
          <br />
          <span style={{ fontSize: "13px" }}>
            ({data.purchase_data?.sub_id})
          </span>
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            {location_settings.currency_sign}
            {data.purchase_data?.latest_invoice.total / 100}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Setup Fee:
          <br />
          <span style={{ fontSize: "13px" }}>
            ({data.purchase_data?.charge_id})
          </span>
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            {location_settings.currency_sign}3
            {/* {data.purchase_data?.latest_invoice.total / 100} */}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" mt={2}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Total:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            {location_settings.currency_sign}
            {data.purchase_data?.price}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
}

export default OrderSummary;
