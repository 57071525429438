// @mui material components
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import MDButton from 'components/MDButton'

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook'
// import TwitterIcon from '@mui/icons-material/Twitter'
// import InstagramIcon from '@mui/icons-material/Instagram'

// Material Dashboard 2 React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 React example components
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard'
// import ProfilesList from 'examples/Lists/ProfilesList'

import { useUserInfo } from 'context/user'

import burceMars from 'assets/images/bruce-mars.jpg'
import { useEffect, useState } from 'react'
import localforage from 'localforage'
// Overview page components
import MDAvatar from 'components/MDAvatar'
import MDTypography from 'components/MDTypography'
import { Icon } from '@mui/material'
import { useLogout } from 'hook/auth'
import { useAgencyInfo } from 'context/agency'
function Profile() {
  const [account_type, setaccount_type] = useState('')
  const [user_details, setuser_details] = useState('')
  const [agency] = useAgencyInfo()
  const [user] = useUserInfo()
  const Logout = useLogout()
  const handleRoute = () => {
    window.open('https://www.facebook.com/groups/ghlstore/', '_blank')
  }
  useEffect(async () => {}, [])
  return (
    <>
      <MDBox mb={2} />
      <Grid container spacing={3} alignItems="center">
        <Grid item ml="auto">
          <MDBox onClick={Logout} sx={{ cursor: 'pointer' }} display="flex" alignItems="center">
            <Icon>logout</Icon>
            <MDTypography
              ml={1}
              mr={5}
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Logout
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <MDBox mt={5} mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex' }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <ProfileInfoCard
              title="profile information"
              // description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                fullName: user.username,
                mobile: user.phone,
                email: user.email,
                accountType: agency?.type,
              }}
              social={[
                {
                  link: 'www.facebook.com/groups/snapshotstore/',
                  icon: <FacebookIcon />,
                  color: 'facebook',
                },
              ]}
              // social={[
              //   {
              //     link  : 'https://www.facebook.com/CreativeTim/',
              //     icon  : <FacebookIcon />,
              //     color : 'facebook',
              //   },
              //   {
              //     link  : 'https://twitter.com/creativetim',
              //     icon  : <TwitterIcon />,
              //     color : 'twitter',
              //   },
              //   {
              //     link  : 'https://www.instagram.com/creativetimofficial/',
              //     icon  : <InstagramIcon />,
              //     color : 'instagram',
              //   },
              // ]}
              // action={{ route: '', tooltip: 'Edit Profile' }}
              action={{ route: '' }}
              shadow={false}
            />

            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <MDButton
          variant="gradient"
          color="info"
          type="submit"
          sx={{ mt: 2, mb: 1, h: 3 }}
          fullWidth
          onClick={handleRoute}
        >
          <FacebookIcon sx={{ h: 2 }} fontSize={'large'} />
          Join Our FaceBook Group
        </MDButton>
      </MDBox>
    </>
  )
}

export default Profile
