import Button from "@mui/material/Button";
import localforage from "localforage";
import { Card, Divider, Grid, IconButton, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import DefaultProjectCard from "./DefaultProjectCard";
import env from "config";
import { useAgencyInfo } from "context/agency";
const Billing = () => {
  const [agency] = useAgencyInfo();

  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(async () => {}, []);
  return (
    <Grid container justifyContent="start" ml={1} bgcolor={"#F0F2F5"}>
      <Grid item xs={11}>
        <MDBox mb={3} bgcolor={"#F0F2F5"}>
          {agency?.stripe != undefined ? (
            <DefaultProjectCard
              bgcolor={"#F0F2F5"}
              action={{
                type: "external",
                route: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_MiMb2iz5PObmx2Gxn7f6OA0ZFK2GWT0I&scope=read_write&redirect_uri=${env.STRIPE.REDIRECT}`,
                target: "_self",
                color: "success",
                label: "Connected",
              }}
            />
          ) : (
            <DefaultProjectCard
              bgcolor={"#F0F2F5"}
              title="Stripe"
              description="N/A"
              action={{
                type: "external",
                route: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_MiMb2iz5PObmx2Gxn7f6OA0ZFK2GWT0I&scope=read_write&redirect_uri=${env.STRIPE.REDIRECT}`,
                target: "_self",
                color: "info",
                label: "Connect",
              }}
            />
          )}
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default Billing;
