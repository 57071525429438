import axios from "axios";
import env from "config";
import { setNotification, useNotifcation } from "context/notification";
import { ReqMethods, ResponseStatus } from "enums";
import { useAuth, useLogout } from "./auth";

const BASE_URL = `${env.API_URL}/v1`;
const BASE_URL_V2 = `${env.API_URL}/v2`;

function useServiceHandler() {
  const [, dispatch] = useNotifcation();
  const Logout = useLogout();

  return (fn) =>
    async (
      method,
      path,
      { query, payload, token, apiKey, toaster, message, error } = {}
    ) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey });
        console.log("API - RESPONSE", res, toaster, message, error);
        toaster &&
          setNotification(dispatch, {
            open: true,
            message: message || res.data.message,
            title: "Success",
          });

        return { response: res.data };
      } catch (err) {
        console.log("API- ERROR", err.response?.data || err);

        // expire error : jwt expired
        if (
          err.response &&
          err.response.status === ResponseStatus.UNAUTHORIZED
        ) {
          setNotification(dispatch, {
            open: true,
            message: error || "session expired",
            title: "Error",
            severity: "error",
          });
          setTimeout(Logout, 4000);
          return { error: err.response?.data || err };
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message;

        toaster &&
          setNotification(dispatch, {
            open: true,
            message: error || customError || err.message,
            severity: "error",
            title: "Error",
          });
        return { error: err.response ? err.response.data : err };
      }
    };
}

function useCallService() {
  const authToken = useAuth();
  const serviceHandler = useServiceHandler();

  const CallService = (
    method,
    path,
    { query, payload, token = true, apiKey = null }
  ) => {
    const pathname = query ? `${path}?${query}` : path;
    const config = {};

    if (token)
      config.headers = { "x-auth-token": `Bearer ${authToken || token}` };
    if (apiKey) config.headers = { apiKey };

    const details = {};

    if (payload) details.payload = payload;
    details.config = config;

    return axios[method](pathname, ...Object.values(details));
  };

  return serviceHandler(CallService);
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods;
  const CallService = useCallService();

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
      CreateTeam: (options) =>
        CallService(POST, `${BASE_URL}/auth/team`, options),
      GetTeam: (options) => CallService(GET, `${BASE_URL}/auth/team`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
      getuserpurchases: (options) =>
        CallService(GET, `${BASE_URL}/user/agency`, options),
    },
    packages: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/packages`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/packages/filter`, options),
    },
    app: {
      get: (options) => CallService(GET, `${BASE_URL}/app`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app`, options),
      create: (options) => CallService(POST, `${BASE_URL}/app`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/app`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/app/filter`, options),
    },
    bundles: {
      get: (options) => CallService(GET, `${BASE_URL}/seller/bundle`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/seller/bundle`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/seller/bundle`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/seller/bundle`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/seller/bundle/filter`, options),
      get_purchases: (options) =>
        CallService(GET, `${BASE_URL}/seller/bundle/get_purchases`, options),
      updatetask: (options) =>
        CallService(PUT, `${BASE_URL}/seller/bundle/updatetask`, options),
      stordata: (options) =>
        CallService(GET, `${BASE_URL}/seller/bundle/stordata`, options),
    },
    categories: {
      get: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/category/getsnaphotcategories`,
          options
        ),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/snapshot/category`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/category/submit`, options),
      delete: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/category/delete/${options.id}`,
          options
        ),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/category/filter`, options),
    },
    location_settings: {
      get: (options) =>
        CallService(
          GET,
          `${BASE_URL_V2}/snapshot/agency/location_settings/filter/${options.id}`,
          options
        ),
    },
    offer: {
      get: (options) => CallService(GET, `${BASE_URL}/offer`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/offer`, options),
      create: (options) => CallService(POST, `${BASE_URL}/offer`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/offer`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/offer/filter`, options),
    },
    snapshots: {
      getsnapshots: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getsnapshots`,
          options
        ),
      getbundles: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getbundles`,
          options
        ),
      getstoresnapshots: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getstoresnapshots`,
          options
        ),
      getcategories: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getcategories`,
          options
        ),
      getstoresnapshots: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getstoresnapshots`,
          options
        ),
      getservice: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getservice`,
          options
        ),
      getdashboard: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/getdashboard`,
          options
        ),
      getsellerdashboard: (options) =>
        CallService(GET, `${BASE_URL}/seller/bundle/getdashboard`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/snapshot/create_snapshot`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/create_snapshot`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/snapshot/create_snapshot`, options),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/create_snapshot/filter`,
          options
        ),
    },
    snapshot_category: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/snapshot/category`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/snapshot/category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/category/filter`, options),
    },
    request_app: {
      get: (options) => CallService(GET, `${BASE_URL}/requestapp`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/requestapp`, options),
      create: (options) => CallService(POST, `${BASE_URL}/requestapp`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/requestapp`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/requestapp/filter`, options),
    },
    purchased_app: {
      get: (options) => CallService(GET, `${BASE_URL}/purchasedapp`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/purchasedapp`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/purchasedapp`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/purchasedapp`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/purchasedapp/filter`, options),
    },
    Tasks: {
      get: (options) => CallService(GET, `${BASE_URL}/tasks`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/tasks`, options),
      sellerupdate: (options) =>
        CallService(PUT, `${BASE_URL}/tasks/seller`, options),

      create: (options) => CallService(POST, `${BASE_URL}/tasks`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/tasks`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/tasks/filter`, options),
      sellertask: (options) =>
        CallService(GET, `${BASE_URL}/tasks/sellertask`, options),
    },
    Purchase_snaphot: {
      getlocations: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/purchaseitems`,
          options
        ),
      getpurchases: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/make_purchase`,
          options
        ),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/snapshot/Purchase_snaphot`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/Purchase_snaphot`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/snapshot/Purchase_snaphot`, options),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/Purchase_snaphot/filter`,
          options
        ),
    },
    purchased_offer: {
      get: (options) => CallService(GET, `${BASE_URL}/purchasedoffer`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/purchasedoffer`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/purchasedoffer`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/purchasedoffer`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/purchasedoffer/filter`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      superadmin: (options) =>
        CallService(PUT, `${BASE_URL}/brand/superadmin`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    marketplace: {
      getCustomers: (options) =>
        CallService(GET, `${BASE_URL}/marketplace/card/customers`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      location_settings: (options) =>
        CallService(
          GET,
          `${BASE_URL_V2}/snapshot/agency/location_settings/filter`,
          options
        ),
      getprices: (options) =>
        CallService(GET, `${BASE_URL}/agency/getprices`, options),
      getmain_agency: (options) =>
        CallService(GET, `${BASE_URL}/agency/main_agency`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
      update_theme: (options) =>
        CallService(POST, `${BASE_URL}/agency/update`, options),
      location_auth: (options) =>
        CallService(PUT, `${BASE_URL}/agency/locationauth`, options),
      getProducts: (options) =>
        CallService(PUT, `${BASE_URL}/agency/getproducts`, options),
    },
    superadmin: {
      getProducts: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/superadmin_settings/workflows`,
          options
        ),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/superadmin_settings/filter`,
          options
        ),
    },
    utils: {
      upload_image: (options) =>
        CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    stripe: {
      // product: (options) => CallService(GET, `${BASE_URL}/services/stripe/products`, options),
      // getCustomer: (options) => CallService(GET, `${BASE_URL}/services/stripe/customers`, options),
      integrateAcocunt: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/agency/stripe/integrate`,
          options
        ),
      get: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/agency/stripe`, options),
      invoices: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/agency/stripe/invoices/${options.id}`,
          options
        ),
      update: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/agency/stripe/update/${options.id}`,
          options
        ),
      create: (options) =>
        CallService(POST, `${BASE_URL}/snapshot/agency/stripe/submit`, options),
      delete: (options) =>
        CallService(
          POST,
          `${BASE_URL}/snapshot/agency/stripe/delete/${options.id}`,
          options
        ),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/agency/stripe/filter/${options.id}`,
          options
        ),
    },
    services: {
      ghl: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
  };

  return APIs;
}
const useUploadImage = () => {
  const AppService = useAppServices();
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData();
    form.append("image", file, file.name);
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    });
  };
};
export { useAppServices, useCallService, useUploadImage };
